"use client";

// src/use-ripple.ts
import { useCallback, useEffect, useState } from "react";
function useRipple(props = {}) {
  const { removeAfter = 1e3, ...otherProps } = props;
  const [ripples, setRipples] = useState([]);
  useEffect(() => {
    const timeoutIds = ripples.map(
      (_, i) => setTimeout(() => {
        setRipples((prevState) => prevState.filter((_2, index) => index !== i));
      }, removeAfter)
    );
    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
    };
  }, [ripples]);
  const onClick = useCallback((event) => {
    const trigger = event.currentTarget;
    const size = Math.max(trigger.clientWidth, trigger.clientHeight);
    const rect = trigger.getBoundingClientRect();
    setRipples((prevRipples) => [
      ...prevRipples,
      {
        key: new Date().getTime(),
        size,
        x: event.clientX - rect.x - size / 2,
        y: event.clientY - rect.y - size / 2
      }
    ]);
  }, []);
  return { ripples, onClick, ...otherProps };
}

export {
  useRipple
};
