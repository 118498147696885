import { Suspense, useState, useEffect, startTransition } from 'react';
import {
  Outlet,
  Link,
  NavLink,
  useLoaderData,
  redirect,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import Modal from 'react-modal';
import { collection, query, orderBy, limit, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { formatDistance } from 'date-fns';
import { ko } from 'date-fns/locale';
import { Spinner } from '@nextui-org/react';

import {
  FrommError,
  frommClient,
  getCloudFrontURL,
  FrommFriend,
  FrommFirebaseMessage,
  FrommChatRoom,
} from '~/src/fromm';

export async function loader(): Promise<FrommChatRoom[]> {
  try {
    if (!frommClient.userInfo) {
      await frommClient.refreshToken();
    }
    let chatRooms = await frommClient.chatRooms();
    return chatRooms;
  } catch (error) {
    if (error instanceof FrommError) {
      throw redirect('/login');
    } else {
      throw error;
    }
  }
}

function useDate(delay: number) {
  let [date, setDate] = useState(new Date());
  useEffect(() => {
    let intervalId = setInterval(() => setDate(new Date()), delay);
    return () => clearInterval(intervalId);
  }, []);
  return date;
}

function Friend({ chatRoom }: { chatRoom: FrommChatRoom }) {
  let navigate = useNavigate();
  let firestore = useFirestore();
  let messageQuery = query(
    collection(firestore, 'root-collection', chatRoom.starId, 'message'),
    where('userId', '==', chatRoom.starId),
    orderBy('createdAt', 'desc'),
    limit(1),
  );
  let message = useFirestoreCollectionData(messageQuery, { idField: 'id' })
    .data[0] as FrommFirebaseMessage;
  let [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => setIsFirstRender(false), []);
  useEffect(() => {
    if ('Notification' in window) {
      if (isFirstRender) {
        Notification.requestPermission();
      } else if (Notification.permission === 'granted') {
        try {
          let notification = new Notification(chatRoom.starNick, {
            body: message.content,
            icon: getCloudFrontURL(
              chatRoom.starProfileUrlThumbnail || chatRoom.starProfileUrl,
            ).href,
          });
          notification.addEventListener('click', () => {
            navigate(chatRoom.starId);
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
  }, [message.content]);
  let date = useDate(60 * 1000);
  return (
    <NavLink
      to={chatRoom.starId}
      className={({ isActive }) =>
        `${
          isActive ? 'font-medium bg-gray-200' : 'hover:bg-gray-100'
        } px-4 py-3 rounded-e-lg flex gap-2 items-center`
      }
    >
      <img
        src={
          getCloudFrontURL(
            chatRoom.starProfileUrlThumbnail || chatRoom.starProfileUrl,
          ).href
        }
        alt={`Profile of ${chatRoom.starNick}`}
        className='w-10 h-10 rounded-full object-cover flex-none'
      />
      <div className='flex flex-col overflow-x-hidden'>
        <div className='font-normal text-sm truncate text-elllipsis'>
          {chatRoom.starNick}
        </div>
        <div className='font-normal text-xs truncate text-elllipsis'>
          {message.content}
        </div>
        <div className='font-normal text-xs text-gray-400'>
          {formatDistance(message.createdAt.toDate(), date, { locale: ko })} 전
        </div>
      </div>
    </NavLink>
  );
}

export default function Friends() {
  let chatRooms = useLoaderData() as FrommChatRoom[];
  let [isModalOpen, setIsModalOpen] = useState(false);
  let [isSidebarOpen, setIsSidebarOpen] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    if (isSidebarOpen) setIsSidebarOpen(false);
  }, [location]);
  return (
    <main className='flex h-screen !h-[100dvh] pl-[env(safe-area-inset-left)] pr-[env(safe-area-inset-right)] pt-[env(safe-area-inset-top)] pb-[env(safe-area-inset-bottom)]'>
      <div
        className={`${
          isSidebarOpen ? 'flex w-full' : 'hidden'
        } lg:w-1/6 lg:flex flex-col overflow-y-auto`}
      >
        <h3 className='px-4 py-4 text-lg font-bold'>
          <Link to='/'>
            <img
              src={new URL('~/src/resources/fromm.svg', import.meta.url).href}
              alt='Fromm Logo'
              className='inline h-6 pb-1 me-1'
            />
            Friends
          </Link>
        </h3>
        {chatRooms
          .filter(chatRoom => chatRoom.enabled)
          .map(chatRoom => (
            <Friend key={chatRoom.starId} chatRoom={chatRoom} />
          ))}
        <button
          className='hover:bg-gray-100 px-4 py-3 rounded-se-lg mt-auto text-start'
          onClick={() => setIsModalOpen(true)}
        >
          <img
            src={getCloudFrontURL(frommClient.userInfo!.fan.profileUrl).href}
            alt='User Profile'
            className='inline me-2 w-8 h-8 rounded-full object-cover'
          />
          {frommClient.userInfo!.fan.nick}
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        overlayClassName='fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75'
        onRequestClose={() => setIsModalOpen(false)}
      >
        <div className='flex flex-col gap-4 rounded-lg bg-white text-center p-2'>
          <h3 className='font-bold pt-4'>Sign Out</h3>
          <p className='text-sm text-gray-500 px-4'>
            Are you sure you want to sign out?
          </p>
          <div className='flex gap-2'>
            <button
              onClick={() => setIsModalOpen(false)}
              className='w-full py-2 rounded-lg bg-white hover:bg-gray-100 font-medium'
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                await frommClient.signOut();
                navigate('/');
              }}
              className='w-full py-2 rounded-lg bg-primary-500 hover:bg-primary-600 text-white font-medium'
            >
              Sign Out
            </button>
          </div>
        </div>
      </Modal>
      <div className={isSidebarOpen ? 'hidden' : 'flex-1'}>
        <Suspense fallback={<Spinner className='h-full w-full' />}>
          <Outlet />
        </Suspense>
      </div>
      <div
        className='text-gray-600 fixed top-0 end-0 end-[env(safe-area-inset-right)] top-[env(safe-area-inset-top)] p-4 lg:hidden bg-white/25 backdrop-blur-lg rounded-es-lg'
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <svg
          className='w-6 h-6'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          viewBox='0 0 24 24'
          stroke='currentColor'
        >
          <path d='M4 6h16M4 12h16m-7 6h7'></path>
        </svg>
      </div>
    </main>
  );
}

export function ChatPlaceholder() {
  return (
    <div className='h-full flex justify-center items-center'>
      <p className='text-lg text-gray-300'>Select any chat from the sidebar</p>
    </div>
  );
}
