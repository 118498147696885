"use client";

// src/ripple.tsx
import { AnimatePresence, motion } from "framer-motion";
import { Fragment, jsx } from "react/jsx-runtime";
var clamp = (value, min, max) => {
  return Math.min(Math.max(value, min), max);
};
var Ripple = ({ ripples = [], motionProps, color = "currentColor", style }) => {
  return /* @__PURE__ */ jsx(Fragment, { children: ripples.map((ripple) => {
    const duration = clamp(0.01 * ripple.size, 0.2, ripple.size > 100 ? 0.75 : 0.5);
    return /* @__PURE__ */ jsx(AnimatePresence, { mode: "popLayout", children: /* @__PURE__ */ jsx(
      motion.span,
      {
        animate: { transform: "scale(2)", opacity: 0 },
        className: "nextui-ripple",
        exit: { opacity: 0 },
        initial: { transform: "scale(0)", opacity: 0.35 },
        style: {
          position: "absolute",
          backgroundColor: color,
          borderRadius: "100%",
          transformOrigin: "center",
          pointerEvents: "none",
          zIndex: 10,
          top: ripple.y,
          left: ripple.x,
          width: `${ripple.size}px`,
          height: `${ripple.size}px`,
          ...style
        },
        transition: { duration },
        ...motionProps
      }
    ) }, ripple.key);
  }) });
};
Ripple.displayName = "NextUI.Ripple";
var ripple_default = Ripple;

export {
  ripple_default
};
